import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import SEO from '../components/seo';
import Layout from '../components/layout';

const Styled404 = styled.div`
	/* 		  		nav 7.6   */
	height: calc(100vh - 7.6rem);
	padding-bottom: 200px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	h1 {
		color: ${(p) => p.theme.color.red};
		padding: 0 1rem;
	}
	p {
		margin: 3rem;
	}
	@media all and (min-width: 1600px) {
		/* 		  		nav 9.88   */
		height: calc(100vh - 9.88rem);
	}
	@media all and (max-width: 800px) {
		padding-bottom: 0;
		height: auto;
		div {
			padding: 8rem 0;
		}
	}
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		height: auto;
		padding: 2rem 0 4rem;
	}
`;

const NotFoundPage = () => {
	return (
		<Layout short={true}>
			<SEO title="Page not found" />
			<Styled404>
				<div>
					<h1>The page you are looking for is not found.</h1>
					<p>Looks like the content you're looking for is no longer here.</p>
					<Link to="/" className="redButton">
						BACK TO HOMEPAGE
					</Link>
				</div>
			</Styled404>
		</Layout>
	);
};

export default NotFoundPage;
